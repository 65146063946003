:root{
  --main-bg-color: #021631;
  --main-bg-color-transparent: #11182763; 
  --main-heading-color: #000;
  --bs-text-color: #4b5563;
}

body{
  color: var(--main-text-color);
}

.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6{
  color: var(--main-heading-color) !important;
}

p, span, b {
  color: var(--bs-text-color);
  font-weight: 500;
}

.section-bg {
  background-color: #f3f4f6;
}

.section-title {
  text-align: center;
  padding-bottom: 30px;
}

.section-title h2::before {
  content: "";
  position: absolute;
  display: block;
  width: 120px;
  height: 1px;
  background: #ddd;
  bottom: 1px;
  left: calc(50% - 60px);
}

.section-title h2::after {
  content: "";
  position: absolute;
  display: block;
  width: 40px;
  height: 3px;
  background: #4b5563;
  bottom: 0;
  left: calc(50% - 20px);
}

.section-title h2 {
  font-size: 32px;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 20px;
  padding-bottom: 20px;
  position: relative;
}

a:hover {
  color: #73c5eb;
  text-decoration: none;
}

@font-face {
  font-family: Jost;
  src: url(/public/fonts/Jost/Jost-VariableFont_wght.ttf);
}

@font-face {
  font-family: "Open Sans";
  src: url(/public/fonts/Open_Sans/OpenSans-VariableFont_wdth\,wght.ttf);
}

@font-face {
  font-family: "Poppins";
  src: url(/public/fonts/Poppins/Poppins-Medium.ttf);
}

h1, h2, h3, h4, h5, h6{
  font-family: "Jost", sans-serif;
}

.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
  color: var(--bs-heading-color);
}

a {
  color: #47b2e4;
  text-decoration: none !important;
}

body{
  font-family: "Open Sans", sans-serif !important;
}

section{
  padding: 60px 0;
  overflow: hidden;
}

.c-center{
  width: fit-content;
  margin: auto;
}