.why-us .icon-box {
  box-shadow: 0px 25px 25px 0 rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  padding: 50px 30px;
  transition: all ease-in-out 0.4s;
  background: #fff;
}

.why-us .icon-box .icon {
  margin-bottom: 10px;
}

.why-us .icon-box .icon svg {
  font-size: 36px;
  transition: 0.3s;
}

.why-us .icon-box h4 {
  font-weight: 500;
  margin-bottom: 15px;
  font-size: 24px;
}

.why-us .icon-box h4 span {
  color: var(--main-heading-color);
  transition: ease-in-out 0.3s;
  font-size: 1.5rem;
}

.why-us .icon-box p {
  line-height: 24px;
  font-size: 14px;
  margin-bottom: 0;
}