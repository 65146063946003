body:has(#mobile-navbar .navbar-collapse.show){
  overflow: hidden;
}

#navbar{
  background-color: var(--main-bg-color);
  transition: 500ms ease all;
}

#navbar .nav-link{
  padding: 0;
  margin: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
}

#navbar a:hover,
.nav-link.active{
  border-bottom: 1px solid white;
}

#navbar .navbar-brand{
  letter-spacing: 2px;
}

#navbar .navbar-brand img{
  margin-right: 10px;
}

#navbar .navbar-brand span{
  color: white;
}

#navbar.transparent{
  background-color: transparent;
}

#navbar a{
 color: white;
}

#mobile-navbar .navbar-toggler{
  float: right;
  border: none;
}

#mobile-navbar .navbar-toggler:focus{
  box-shadow: none;
}

/* TOGGLER COLOR WHITE */
#mobile-navbar .navbar-toggler-icon{
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

#mobile-navbar a:hover,
#mobile-navbar .nav-link.active{
  border: none;
}

#mobile-navbar .nav-item:has(a:hover),
#mobile-navbar .nav-item:has(.nav-link.active){
  border: none;
  background-color: #3a3b3d;
  border-radius: 10px;
}

#mobile-navbar .navbar-collapse{
  display: block;
  position: fixed;
  top: 0;
  padding: 60px 0;
  width: 100%;
  height: 100vh;
  transition: left 700ms ease;
  background-color: #212529;
  width: 60%;
  border: none;
}

#mobile-navbar .navbar-collapse.show{
  left: 0;
}

#mobile-navbar .navbar-collapse.show .overlay{
  z-index: -1;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.3);
}

#mobile-navbar .navbar-collapse.hidden{
  left: -60%
}

#mobile-navbar .navbar-collapse ul li{
  padding: 5px 20px;
  margin: 5px 10px;
}

#mobile-navbar .navbar-collapse ul li a{
  text-align: center;
  border-radius: 0;
}

#mobile-navbar .navbar-collapse ul li a.active{
  background-color: transparent;
}