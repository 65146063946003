footer{
  background-color: var(--main-bg-color);
  color: white !important;
}

footer .flex-column li a{
  color: white !important;
}

footer h5,
footer p{
  color: white !important;
}